<template>
  <b-card title="Item Delivered">
    <b-col cols="12">
      <b-row>
        <b-col><b-button @click="back" variant="primary"> Back </b-button></b-col>  
      </b-row>
      <br>
      <b-row style="margin-bottom: 15px">
        <b-col cols="3"><strong>Item Model</strong></b-col>
        <b-col>: {{ itemModel }} </b-col>
      </b-row>
      <b-row style="margin-bottom: 15px">
        <b-col cols="3"><strong>Item Name</strong></b-col>
        <b-col>: {{ itemName }} </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <strong>DO Number :</strong>
          <b-form-input
            v-model="searchSapDo"
            type="search"
            placeholder=""
            style="float: right; margin-top: 20px; margin-bottom: 15px;"
          />
        </b-col>
        <b-col md="3">
          <strong>Barcode :</strong>
          <b-form-input
            v-model="searchBarcode"
            type="search"
            placeholder=""
            style="float: right; margin-top: 20px; margin-bottom: 15px;"
          />
        </b-col>
        <b-col md="3">
          <strong>Serial :</strong>
          <b-form-input
            v-model="searchSerial"
            type="search"
            placeholder=""
            style="float: right; margin-top: 20px; margin-bottom: 15px;"
          />
        </b-col>
        <b-col md="3">
          <strong>Status :</strong>
          <!-- <b-form-select
            :options="optionsStatus"
            v-model="searchStatus"
            type="search"
            placeholder=""
            style="float: right; margin-top: 20px; margin-bottom: 15px;"
          /> 

          <b-form-input
            style="float: right; margin-top: 20px; margin-bottom: 15px;"
            v-model="searchStatus"
            list="input-list"
            id="input-with-list"
          ></b-form-input>
          <b-form-datalist
            id="input-list"
            :options="optionsStatus"
          ></b-form-datalist>

          -->
          <b-form-select
            style="margin-top: 20px; margin-bottom: 15px;"
            v-model="searchStatus"
            :options="optionsStatus"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <strong>Delivery Type :</strong>
          <b-form-select
            style="margin-top: 20px; margin-bottom: 15px;"
            v-model="searchDoType"
            :options="optionsDeliveryType"
          ></b-form-select>
          <!-- <b-form-input
            v-model="searchDoType"
            type="search"
            placeholder=""
            style="float: right; margin-top: 20px; margin-bottom: 15px;"
          /> 

          <b-form-input
            style="float: right; margin-top: 20px; margin-bottom: 15px;"
            v-model="searchDoType"
            list="input-delivery-type"
            id="input-with-bryan"
          ></b-form-input>
          <b-form-datalist
            id="input-delivery-type"
            :options="optionsDeliveryType"
          ></b-form-datalist>
          -->
        </b-col>
        <b-col md="3">
          <strong>Project/Route :</strong>
          <b-form-select
            style="margin-top: 20px; margin-bottom: 15px;"
            v-model="searchProjectRoute"
            :options="optionsProjectRoute"
          ></b-form-select>
          <!-- <v-select
            class="style-chooser"
            v-model="searchProjectRoute"
            :options="optionsProjectRoute"
            label="text"
            style="margin-top: 20px; margin-bottom: 15px;"
          /> 
          <b-form-input
            style="float: right; margin-top: 20px; margin-bottom: 15px;"
            v-model="searchProjectRoute"
            list="input-project"
            id="input-with-list"
          ></b-form-input>
          <b-form-datalist
            id="input-project"
            :options="optionsProjectRoute"
          ></b-form-datalist>
          -->
        </b-col>
        <b-col md="3">
          <strong>Delivery Date From :</strong>
          <b-form-datepicker
            reset-button
            v-model="searchDateForm"
            :max="endDate"
            :min="minmin"
            style="float: right; margin-top: 20px; margin-bottom: 15px;"
          />
        </b-col>
        <b-col md="3">
          <strong>Delivery Date End :</strong>
          <b-form-datepicker
            reset-button
            v-model="searchDateEnd"
            :max="maxDate"
            style="float: right; margin-top: 20px; margin-bottom: 15px;"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" style="margin-bottom:15px">
          <b-btn
            style="float: right"
            variant="primary"
            @click="searchByModel()"
          >
            Search
          </b-btn>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table :items="dealers" :fields="fields" show-empty
            responsive
            >deliveryDate
            <template #cell(deliveryDate)="row">
              {{ row.item.deliveryDate | formatDate }}
            </template>
            <template #cell(distributionChannel)="row">
              {{ isRoute(row.item.distributionChannel)?"Route" : "Project" }}
            </template>
            <template #cell(deliveryType)="row">
              <div
                v-if="row.item.priority == '03' || row.item.priority == '05'"
              >
                Self Collect
              </div>
              <div v-else>Delivery</div>
            </template>
            <template #cell(overdueAmount)="row">
              {{ row.item.overdueAmount.$numberDecimal }}
            </template>
            <template #cell(actions)="row">
              <b-dropdown
                id="dropdown-dropleft"
                variant="primary"
                size="sm"
                text="Menu"
                no-caret
              >
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item>
                  <b-button
                    @click="doEditDealer(row)"
                    variant="info"
                    class="mr-1"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template #cell(status)="row">
              {{ row.item.status === "" ? "Active" : "Inactive" }}
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
            style="float: right"
            first-number
            last-number
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import lodash from "lodash";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      optionsStatus: [
        { value: "",text: "-- All --"},
        { value: "Pre", text: "Pre" },
        { value: "On-Hold", text: "On-Hold" },
      ],
      optionsProjectRoute: [
        { value: "",text: "-- All --"},
        { value: "Project", text: "Project" },
        { value: "Route", text: "Route" },
      ],
      optionsDeliveryType: [
        { value: "",text: "-- All --"},
        { value: "Delivery", text: "Delivery" },
        { value: "Self Collect", text: "Self Collect" },
      ],
      itemModelName: "",
      itemName: "",
      dealers: [],
      form: {
        data: ["Dealer 1", "Dealer 2"],
      },
      fields: [
        { key: "sapDo", label: "DO Number" },
        { key: "barcode", label: "Barcode" },
        { key: "serial", label: "Serial No" },
        { key: "warrantyStatus", label: "Status" },
        { key: "deliveryType", label: "Delivery Type" },
        {
          key: "distributionChannel",
          label: "Project/\nRoute",
        },
        { key: "deliveryDate", label: "Delivery Date" },
      ],
      itemModel: "",
      
      maxDate: "",
      startDate: "",
      endDate: "",
      searchSapDo: "",
      searchBarcode: "",
      searchSerial: "",
      searchStatus: "",
      searchDoType: "",
      searchProjectRoute: "",
      searchDateForm: "",
      searchDateEnd: "",
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      tableLoading: true,
      toFilter: "",
      selectedFilter: "DO",
      selectedField: "model",
      type: "Set Up",
      typeOptions: ["Set Up", "Not Set Up", "Skip Scan"],
      typeDo: "Set Up",
      typeDoOptions: ["Set Up", "Not Set Up"],
    };
  },
  mounted() {
    let data = {
      dealerCode: this.$route.params.dealerCode,
      itemModel: this.$route.params.itemModel,
    };
    // this.getDelivered(data).then((el) => {
    //   console.log("el", el);
    //   this.dealers = el;
    //   this.itemModelName = el[0].description;
    //   this.itemModel = data.itemModel;
    // });
    this.queryDelivery();
  },
  methods: {
    ...mapActions({
      getDelivered: "dashboard/getDelivered",
      getDealers: "dealer/getDealerWithQuery",
      filterDeliveredDashboard: "dashboard/filterDeliveredDashboard",
    }),
    isRoute(distributionChannel){
      return ["DL","DR","DS","M1","M2","M3"].includes(distributionChannel)
    },
    back(){
      this.$router.push({name:`dashboard-dealer`, query:{dealerCode:this.$route.params.dealerCode}})
    },
    onPageChange(page) {
      console.log("REGE YO", page);
      this.currentPage = page;
      this.queryDelivery();
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      console.log("totalData", totalData);
      this.totalRows = totalData;
    },
    queryDelivery() {
      let dealerCode = this.$route.params.dealerCode;
      let itemModel = this.$route.params.itemModel;
      let sapDo = this.$route.params.sapDo;
      this.filterDeliveredDashboard({
        projectOrRoute: this.searchProjectRoute,
        startDate: this.searchDateForm,
        endDate: this.searchDateEnd,
        warrantyStatus: this.searchStatus,
        barcode: this.searchBarcode,
        priority: this.searchDoType,
        dealerCode: dealerCode,
        itemModel: itemModel,
        serial: this.searchSerial,
        sapDo: this.searchSapDo? this.searchSapDo : sapDo,
        page: this.currentPage,
        entry: this.perPage,
      })
        .then((res) => {
          console.log("THIS>PAGE", res);
          this.itemName = res.data[0].description;
          this.itemModel = res.data[0].itemModel;
          this.dealers = res.data;
          this.processMetadata(res.metadata);
        })
        .catch((err) => {
          console.log("PAGE_ERROR_WARR");
          console.log({ err });
        });
    },
    searchByModel() {
      let dealerCode = this.$route.params.dealerCode;
      let itemModel = this.$route.params.itemModel;

      let payload = {
        projectOrRoute: this.searchProjectRoute,
        startDate: this.searchDateForm,
        endDate: this.searchDateEnd,
        warrantyStatus: this.searchStatus,
        barcode: this.searchBarcode,
        priority: this.searchDoType,
        dealerCode: dealerCode,
        itemModel: itemModel,
        serial: this.searchSerial,
        sapDo: this.searchSapDo,
        entry: this.perPage,
      };
      this.filterDeliveredDashboard(payload).then((el) => {
        this.dealers = el.data;
        this.processMetadata(el.metadata);
      });
    },

    dropDownMenuClick(event) {
      this.selectedFilter = event.target.text;
      this.selectedField = event.target.getAttribute("field");

      //every change selected filter, will reset type to :
      this.typeDo = "Set Up";
      this.type = "Set Up";
    },
    clearFilter() {
      this.selectedField = "sapDo";
      this.selectedFilter = "DO";
      this.toFilter = undefined;
      this.type = "Set Up";
      this.typeDo = "Set Up";

      this.queryDelivery();
    },
    getFilter() {
      if (this.selectedFilter === null) {
        return {};
      }
      let filter = {};

      let tempValue = this.toFilter;
      let tempFilter = this.selectedField;
      if (this.selectedFilter === "Warehouse") {
        tempValue = this.warehouse;
      }

      if (this.type === "Not Set Up" || this.typeDo === "Not Set Up") {
        tempValue = "Not Scan";
      }

      if (this.selectedFilter === "Serial" && this.type === "Skip Scan") {
        tempFilter = "skip";
        tempValue = true;
      }

      if (this.selectedFilter === "Barcode" && this.type === "Skip Scan") {
        tempFilter = "skip";
        tempValue = true;
      }

      filter[tempFilter] = tempValue;

      return filter;
    },
    doFilter() {
      let filter = this.getFilter();
      this.getAllInventories({
        entry: this.perPage,
        ...filter,
      }).then((data) => this.processMetadata(data.metadata));
    },
    doFiltering: lodash.debounce(function(value) {
      this.search = value;
      this.currentPage = 1;
      this.queryDealer();
    }, 500),
  },
  computed: {
    ...mapGetters({
      getDataDelivered: "dashboard/getDelivered",
    }),
  },
  created() {
    document.title = "Dashboard Delivered | RSP";
    let now = new Date();
    let yearAgo = new Date(
      now.getFullYear() - 1,
      now.getMonth() + 1,
      now.getDate()
    );

    let monthAgo = new Date();
    monthAgo.setMonth(monthAgo.getMonth() - 1);

    this.maxDate = now;
    this.startDate = monthAgo;
    this.endDate = now;
    // this.getDealers({
    //   page: this.currentPage,
    //   entry: this.perPage,
    // }).then((data) => this.processMetadata(data.metadata));
  },
};
</script>
<style scoped>
.cart__area-columns {
  display: grid;
  grid-template-columns: 40% 15% 15% 15% auto;
  column-gap: 15px;
  margin: 0 0 5px 0;
}
</style>
